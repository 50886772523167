<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5 v-if="(userId == katProses.user && katProses.proses == 1) ||
                    (userId == katProses.user && katProses.proses == 2)
                    ">
                    Persetujuan FPP (Formulir Pengajuan Permintaan, Perbaikan &
                    Penggantian)
                </h5>
                <h5 v-if="userId == 9">Surat Tugas</h5>
            </template>

            <!-- Create Surat Tugas -->
            <b-card>
                <template v-if="userId == katProses.user && katProses.proses == 3">
                    <div class="mt-4">
                        <b-form @submit.prevent="onConfirm">
                            <div class="row">
                                <div class="col-md-6 col-lg-4">
                                    <b-form-group label="Pilih Type Maintenance" label-for="typeMaintenance">
                                        <b-form-select @change="changeTypeMaintenance" id="typeMaintenance"
                                            v-model="typeMaintenanceSelected" :options="typeMaintenance"></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6 col-lg-8">
                                    <b-form-group v-if="typeMaintenanceSelected === 'internal'" id="fg-create-maintenance"
                                        label="Petugas Pemeliharaan" label-for="create-maintenance" class="required">
                                        <multiselect placeholder="Pilih petugas" class="multiselect" label="name"
                                            track-by="id" v-model="formMaintenance.id_maintenance" :option-height="104"
                                            :taggable="false" :show-labels="false" :close-on-select="false"
                                            :clear-on-select="false" :preserve-search="true" :multiple="true"
                                            :options="optionsMaintenance" @tag="addTag">
                                            <template slot="option" slot-scope="props">
                                                <template v-if="props.option.status == 1">
                                                    <img class="option__image" :src="`${VUE_APP_ASSET}/` +
                                                        props.option.foto
                                                        " :alt="props.option.foto" />

                                                    <div class="option__desc">
                                                        <span class="option__title">{{
                                                            props.option.name
                                                        }}</span>
                                                    </div>
                                                </template>
                                            </template>
                                        </multiselect>
                                    </b-form-group>
                                    <b-form-group v-if="typeMaintenanceSelected === 'vendor'" label="Nama Vendor"
                                        label-for="vendorName">
                                        <b-form-input id="vendorName" v-model="vendorName" type="text"
                                            placeholder="Nama Vendor..." required></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <b-form-group id="fg-date-maintenance" label="Tanggal Bertugas"
                                        label-for="date-maintenance" class="required">
                                        <date-picker v-model="formMaintenance.date" placeholder="Contoh: 25 Apr 2023"
                                            :config="config"></date-picker>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6 col-lg-8">
                                    <b-form-group id="memo" label="Pilih Memo" label-for="create-maintenance"
                                        class="required">
                                        <multiselect placeholder="Pilih Memo" class="multiselect" label="name" track-by="id"
                                            v-model="formMaintenance.id_maintenance" :option-height="104" :taggable="false"
                                            :show-labels="false" :close-on-select="false" :clear-on-select="false"
                                            :preserve-search="true" :multiple="true" :options="optionsMaintenance"
                                            @tag="addTag">
                                            <template slot="option" slot-scope="props">
                                                <template v-if="props.option.status == 1">
                                                    <img class="option__image" :src="`${VUE_APP_ASSET}/` +
                                                        props.option.foto
                                                        " :alt="props.option.foto" />

                                                    <div class="option__desc">
                                                        <span class="option__title">{{
                                                            props.option.name
                                                        }}</span>
                                                    </div>
                                                </template>
                                            </template>
                                        </multiselect>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4" v-if="typeMaintenanceSelected === 'internal'">
                                    <b-form-group id="fg-create-barang" label="Material/Bahan" label-for="create-barang"
                                        class="required">
                                        <b-button variant="outline-primary" class="btn-labeled font-weight-bold"
                                            @click="$bvModal.show('m-create-material')"><span class="btn-label"><i
                                                    class="fa fa-plus"></i></span>Tambah Material/Bahan</b-button>
                                    </b-form-group>
                                </div>
                            </div>


                            <div v-if="formMaintenance.id_barang.length !== 0">
                                <b-row>
                                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <b-row>
                                            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                <span class="font-my-style">Keranjang Material/Bahan
                                                </span>
                                                <b-badge v-b-popover.hover.top="'Material/Bahan hanya bisa disimpan ke dalam keranjang, jika jumlahnya > 0 dan < jumlah stok.'
                                                    " title="Catatan" pill variant="primary"><i
                                                        class="fa fa-info"></i></b-badge>
                                            </b-col>
                                            <b-col cols="12" sm="12" md="2" lg="2" class="text-center hide-stock">
                                                <span class="font-my-style align-middle">Stok </span>
                                            </b-col>
                                            <b-col cols="12" sm="12" md="3" lg="3" class="text-center hide-quantity">
                                                <span class="font-my-style required">Jumlah</span>
                                            </b-col>
                                            <b-col cols="12" sm="12" md="1" lg="1" class="text-center hide-action-remove">
                                                <span class="font-my-style">#</span>
                                            </b-col>
                                        </b-row>

                                        <hr />

                                        <b-row class="mt-2 view-row-cart-mobile" v-bind:key="index"
                                            v-for="(option, index) in formMaintenance.id_barang">
                                            <b-col cols="12" sm="12" md="12" lg="6" class="view-col-cart-mobile"
                                                style="align-self: center">
                                                <span class="my-name-material">{{
                                                    option.name
                                                }}</span>
                                            </b-col>

                                            <b-col cols="7" sm="7" md="9" lg="2" xl="2"
                                                class="position-stock-mobile view-col-cart-mobile"
                                                style="align-self: center">
                                                <template>
                                                    <span class="my-stock">Stok: </span>
                                                    <span> {{ option.stock }}</span>
                                                </template>
                                            </b-col>

                                            <b-col cols="5" sm="5" md="3" lg="3" xl="3"
                                                class="text-center d-flex position-quantity-mobile view-col-cart-mobile"
                                                style="align-self: center">
                                                <b-button class="d-inline-block" variant="outline-success"
                                                    @click="increment(index, option.stock)"><i
                                                        class="fa fa-plus"></i></b-button>
                                                <b-form-input class="ml-1 mr-1 text-center" type="number" :min="1"
                                                    :max="option.stock" v-model="formMaintenance.quantity[index]"
                                                    required></b-form-input>
                                                <b-button class="d-inline-block" variant="outline-success"
                                                    @click="decrement(index)"><i class="fa fa-minus"></i></b-button>
                                            </b-col>

                                            <b-col cols="12" sm="12" md="1" lg="1" class="text-center hide-action-remove"
                                                style="align-self: center">
                                                <b-button variant="outline-danger" @click="removeBarang(index)">
                                                    <i class="fa fa-trash"></i>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </div>

                            <div class="mt-4">
                                <template v-if="typeMaintenanceSelected === 'internal'">
                                    <template v-if="formMaintenance.id_maintenance.length < 1 ||
                                        formMaintenance.id_memo.length < 1
                                        ">
                                        <b-button variant="primary" class="btn-labeled" :pressed="true" disabled><span
                                                class="btn-label"><i class="fa fa-save"></i></span>Simpan</b-button>
                                    </template>
                                    <template v-else>
                                        <b-button type="submit" variant="primary" class="btn-labeled"><span
                                                class="btn-label"><i class="fa fa-save"></i></span>Simpan</b-button>
                                    </template>
                                </template>
                                <template v-if="typeMaintenanceSelected === 'vendor'">
                                    <div>
                                        <b-button type="submit" variant="primary" class="btn-labeled"><span
                                                class="btn-label"><i class="fa fa-save"></i></span>Simpan</b-button>
                                    </div>
                                </template>
                            </div>
                        </b-form>
                    </div>
                </template>
            </b-card>

            <!-- Select All For Mobile -->
            <div class="check-all-for-mobile">
                <!-- Approve 1 -->
                <template v-if="userId == katProses.user && katProses.proses == 1">
                    <template v-if="approve1 > 0">
                        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" v-model="allSelected"
                            @click="selectAll" />

                        <label class="ml-1 font-weight-bold" for="styled-checkbox-1">Pilih Semua Pengajuan</label>
                    </template>

                    <template v-else-if="approve1 == 0">
                        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" :disabled="true" />

                        <label class="ml-1 font-weight-bold" for="styled-checkbox-1">Pilih Semua Pengajuan</label>
                    </template>
                </template>

                <!-- Approve 2 -->
                <template v-if="userId == katProses.user && katProses.proses == 2">
                    <template v-if="approve2 > 0">
                        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" v-model="allSelected"
                            @click="selectAll" />
                        <label class="ml-1 font-weight-bold" for="styled-checkbox-1">Pilih Semua Pengajuan</label>
                    </template>

                    <template v-else-if="approve2 == 0">
                        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" :disabled="true" />
                        <label class="ml-1 font-weight-bold" for="styled-checkbox-1">Pilih Semua Pengajuan</label>
                    </template>
                </template>

                <!-- Approve 3 -->
                <template v-if="userId == katProses.user && katProses.proses == 3">
                    <template v-if="approve3 > 0">
                        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" v-model="allSelected"
                            @click="selectAll" />

                        <label class="ml-1 font-weight-bold" for="styled-checkbox-1">Pilih Semua Pengajuan</label>
                    </template>

                    <template v-else-if="approve3 == 0">
                        <input class="styled-checkbox" id="styled-checkbox-9" type="checkbox" :disabled="true" />
                        <label class="ml-1 font-weight-bold" for="styled-checkbox-1">Pilih Semua Pengajuan</label>
                    </template>
                </template>
            </div>
        </b-card>

        <!-- Modal Add Material -->
        <b-modal id="m-create-material" size="lg" title="Daftar Material/Bahan" ref="my-modal" no-close-on-backdrop
            no-close-on-esc hide-footer hide-header>
            <b-row>
                <b-col>
                    <div>
                        <b-form-group>
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-button variant="success" @click="resultMaterial">
                                        <i class="fa fa-search"></i> Cari
                                    </b-button>
                                </b-input-group-prepend>
                                <b-form-input type="text" placeholder="Nama Material" v-model="findMaterial"
                                    @change="resultMaterial"></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="primary" @click="onResetMaterial"><i
                                            class="fa fa-undo"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </div>
                </b-col>
            </b-row>

            <b-row v-show="!loading">
                <template v-if="lengthFindMaterial > 0">
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6" v-for="(data, index) in optionsMaterial"
                        v-bind:key="data.id">
                        <b-card :class="data.kelas">
                            <div class="barang-details">
                                <b-row>
                                    <b-col cols="9" sm="9" md="9" lg="9" xl="9" style="align-self: center">
                                        <span class="font-weight-bold">{{ data.nama }}</span><br />
                                        <b-row>
                                            <b-col cols="4" sm="4" md="4" lg="4" xl="4">Satuan</b-col>
                                            <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.satuan }}</b-col>
                                            <b-col cols="4" sm="4" md="4" lg="4" xl="4">Detail</b-col>
                                            <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.detail }}</b-col>
                                            <b-col cols="4" sm="4" md="4" lg="4" xl="4">Stok</b-col>
                                            <template v-if="data.stok <= 0 || data.stok == '-'">
                                                <b-col cols="8" sm="8" md="8" lg="8" xl="8">: <b-badge
                                                        variant="danger">Habis</b-badge></b-col>
                                            </template>

                                            <template v-else>
                                                <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.stok }}</b-col>
                                            </template>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="3" sm="3" md="3" lg="3" xl="3" class="text-right"
                                        style="align-self: center">
                                        <template v-if="data.stok <= 0 || data.stok == '-'">
                                            <b-button size="sm" variant="outline-secondary" disabled><i
                                                    class="fa fa-plus"></i></b-button>
                                        </template>

                                        <template v-else>
                                            <b-button size="sm" variant="outline-success" @click="
                                                addToFormMaintenance(
                                                    data.id_tipe,
                                                    data.nama,
                                                    data.stok,
                                                    data.pic,
                                                    index
                                                )
                                                "><i class="fa fa-plus"></i>
                                            </b-button>
                                        </template>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                    </b-col>
                </template>

                <template v-else>
                    <b-col sm="12" class="text-center pt-5 pb-5">
                        <span>Material tidak ditemukan &#128532;</span>
                    </b-col>
                </template>
            </b-row>

            <!-- Load Data -->
            <b-row v-show="loading" class="text-center">
                <b-col class="mb-5 mt-5">
                    <b-spinner small variant="secondary"></b-spinner>
                    <span class="text-secondary"> Harap Tunggu...</span>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="12" md="12" lg="10" xl="10" class="mb-3">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
                        @input="onPageChange"></b-pagination>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="2" xl="2">
                    <b-button block class="btn-labeled btn-labeled-for-mobile" variant="dark" @click="hideModal"><span
                            class="btn-label my-filter"><i class="fa fa-times"></i></span>Tutup</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <!-- Modal Update User Maintenance -->
        <b-modal id="m-update-user-maintenance" size="md" title="Ubah Petugas Pemeliharaan & Tanggal Bertugas"
            ref="my-modal" no-close-on-backdrop no-close-on-esc hide-footer @hidden="hideModal">
            <LoadingWidget v-if="initStatusUpdateUserMaintenance != 1" :status="initStatusUpdateUserMaintenance"
                @init="showModalUpdateUserMaintenance" />
            <b-form v-else @submit.prevent="onConfirmUpdateMaintenance">
                <b-form-group id="fg-update-user-maintenance" label="Petugas" label-for="update-user-maintenance"
                    class="required">
                    <multiselect placeholder="Pilih Petugas" class="multiselect" label="name" track-by="id"
                        v-model="formUpdate.memo_maintenance" :option-height="104" :taggable="false" :show-labels="false"
                        :close-on-select="false" :clear-on-select="false" :preserve-search="true" :multiple="true"
                        :options="optionsMaintenance" @tag="addTag" @remove="toggleUnSelectMarket"
                        @select="checkValueUserMaintenance">
                        <template slot="option" slot-scope="props">
                            <template v-if="props.option.status == 1">
                                <img class="option__image" :src="`${VUE_APP_ASSET}/` + props.option.foto
                                    " :alt="props.option.foto" />
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </div>
                            </template>
                        </template>
                    </multiselect>
                </b-form-group>

                <b-form-group id="fg-date-maintenance" label="Tanggal Bertugas" label-for="date-maintenance"
                    class="required">
                    <date-picker v-model="formUpdate.date" placeholder="Contoh: 25 Apr 2023" :config="config"
                        :readonly="lengthNewUserMaintenance > 0"></date-picker>
                </b-form-group>

                <div class="mt-4 text-right">
                    <b-button type="submit" variant="primary" class="btn-labeled">
                        <span class="btn-label" v-show="!loading"><i class="fa fa-save"></i></span>

                        <span v-show="loading" class="btn-label">
                            <b-spinner small variant="light"></b-spinner>
                        </span>
                        Simpan Perubahan
                    </b-button>

                    <b-button variant="dark" class="ml-2 btn-labeled" @click="hideModal"><span class="btn-label"><i
                                class="fa fa-times"></i></span>Tutup</b-button>
                </div>
            </b-form>
        </b-modal>

        <!-- Modal Update Material -->
        <b-modal id="m-update-material" size="lg" title="Ubah Material/Bahan" ref="my-modal" no-close-on-backdrop
            no-close-on-esc hide-footer hide-header @hidden="hideModal">
            <LoadingWidget v-if="initStatusUpdateMaterial != 1" :status="initStatusUpdateMaterial"
                @init="showModalUpdateMaterial" />
            <template v-else>
                <b-button v-b-toggle.collapse-update-material class="btn-labeled" variant="primary"
                    @click="collapseUpdateAction">
                    <template v-if="!collapseUpdate">
                        <span class="btn-label"><i class="fa fa-chevron-down"></i></span>
                    </template>

                    <template v-else>
                        <span class="btn-label"><i class="fa fa-chevron-up"></i></span>
                    </template>
                    Tambah Material/Bahan
                </b-button>

                <b-collapse id="collapse-update-material" class="mt-2">
                    <b-card class="barang-card" id="card-material">
                        <!-- Find Material -->
                        <b-row>
                            <b-col>
                                <b-form-group>
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <b-button variant="success" @click="resultMaterial">
                                                <i class="fa fa-search"></i> Cari
                                            </b-button>
                                        </b-input-group-prepend>
                                        <b-form-input type="text" placeholder="Nama Material" v-model="findMaterial"
                                            @change="resultMaterial"></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="pgiBtn" name="resetTableBtn" @click="onResetMaterial"><i
                                                    class="fa fa-undo"></i></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Content Material -->
                        <b-row v-show="!loading">
                            <template v-if="lengthFindMaterial > 0">
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6" v-for="(data, index) in optionsMaterial"
                                    v-bind:key="data.id">
                                    <b-card :class="data.kelas">
                                        <div class="barang-details">
                                            <b-row>
                                                <b-col cols="9" sm="9" md="9" lg="9" xl="9" style="align-self: center">
                                                    <span class="font-weight-bold">{{ data.nama }}</span><br />
                                                    <b-row>
                                                        <b-col cols="4" sm="4" md="4" lg="4" xl="4">Satuan</b-col>
                                                        <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.satuan
                                                        }}</b-col>
                                                        <b-col cols="4" sm="4" md="4" lg="4" xl="4">Detail</b-col>
                                                        <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.detail
                                                        }}</b-col>
                                                        <b-col cols="4" sm="4" md="4" lg="4" xl="4">Stok</b-col>
                                                        <template v-if="data.stok <= 0 || data.stok == '-'">
                                                            <b-col cols="8" sm="8" md="8" lg="8" xl="8">:
                                                                <b-badge variant="danger">Habis</b-badge></b-col>
                                                        </template>

                                                        <template v-else>
                                                            <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.stok
                                                            }}</b-col>
                                                        </template>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="3" sm="3" md="3" lg="3" xl="3" class="text-right"
                                                    style="align-self: center">
                                                    <template v-if="data.stok <= 0 || data.stok == '-'">
                                                        <b-button size="sm" variant="outline-secondary" disabled><i
                                                                class="fa fa-plus"></i></b-button>
                                                    </template>

                                                    <template v-else>
                                                        <b-button size="sm" variant="outline-success" @click="
                                                            addMaterialMaintenance(
                                                                data.id_tipe,
                                                                data.nama,
                                                                data.stok,
                                                                data.pic,
                                                                index
                                                            )
                                                            "><i class="fa fa-plus"></i></b-button>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-card>
                                </b-col>
                            </template>

                            <template v-else>
                                <b-col sm="12" class="text-center pt-5 pb-5">
                                    <span>Material tidak ditemukan &#128532;</span>
                                </b-col>
                            </template>
                        </b-row>

                        <!-- Load Data -->
                        <b-row v-show="loading" class="text-center">
                            <b-col class="mb-5 mt-5">
                                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                <span class="font-weight-bold text-secondary">
                                    Harap tunggu...</span>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" sm="12" md="10" lg="10" xl="10" class="mb-3">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                    aria-controls="my-table" @input="onPageChange"></b-pagination>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-collapse>

                <b-form class="mt-4" @submit.prevent="swtUpdateMaterial">
                    <b-row class="mb-5">
                        <b-col cols="12" sm="12" md="10" lg="10" xl="10">
                            <b-row class="mb-4">
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <span class="font-my-style">Keranjang Material/Bahan</span>
                                </b-col>
                                <b-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center hide-quantity">
                                    <span class="font-weight-bold required">Jumlah</span>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center hide-action-remove">
                                    <span class="font-weight-bold">#</span>
                                </b-col>
                            </b-row>

                            <template v-if="lengthUpdateMaterial >= 1">
                                <b-row class="mt-2 mb-2 view-row-cart-mobile" v-bind:key="option.id[index]"
                                    v-for="(option, index) in formUpdateMaterial.barang">
                                    <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="view-col-cart-mobile"
                                        style="align-self: center">
                                        <span class="my-name-material">{{ option.tipe }} </span>
                                    </b-col>

                                    <b-col cols="10" sm="10" md="7" lg="3" xl="3"
                                        class="position-stock-mobile view-col-cart-mobile" style="align-self: center">
                                        <template>
                                            <span class="my-stock">Jumlah: </span>
                                            <span> {{ option.quantity }}</span>
                                        </template>
                                    </b-col>

                                    <b-col cols="2" sm="2" md="5" lg="3" xl="3"
                                        class="position-trash-mobile view-col-cart-mobile" style="align-self: center">
                                        <b-button variant="outline-danger" @click="
                                            removeMaterialLive(
                                                option.id_tipe,
                                                index,
                                                option.quantity,
                                                option.tipe,
                                                option.pic,
                                                (option.loadingRemoveMaterial = true)
                                            )
                                            ">
                                            <span v-show="!option.loadingRemoveMaterial"><i class="fa fa-trash"></i></span>

                                            <span v-show="option.loadingRemoveMaterial"
                                                class="spinner-border spinner-border-sm"></span>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </template>

                            <template v-else>
                                <b-row>
                                    <b-col cols="12" class="text-center">
                                        <b-badge pill variant="danger">Keranjang material kosong!</b-badge>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-col>

                        <b-col md="12" class="mt-0 mb-0 hide-action-remove">
                            <hr />
                        </b-col>

                        <b-col cols="12" sm="12" md="12" lg="10" xl="10">
                            <b-row class="mb-4" v-if="lengthBarangNew > 0">
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <span class="font-my-style">Keranjang Material/Bahan Tambahan
                                    </span>
                                    <b-badge v-b-popover.hover.top="'Material/Bahan tambahan hanya bisa disimpan jika jumlahnya > 0 dan < jumlah stok.'
                                        " title="Catatan" pill variant="primary"><i class="fa fa-info"></i></b-badge>
                                </b-col>
                                <b-col cols="12" sm="12" md="12" lg="2" xl="2" class="text-center hide-stock">
                                    <span class="font-my-style align-middle">Stok </span>
                                </b-col>
                                <b-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center hide-quantity">
                                    <span class="font-my-style required">Jumlah</span>
                                </b-col>
                                <b-col cols="12" sm="12" md="12" lg="1" xl="1" class="text-center hide-action-remove">
                                    <span class="font-my-style">#</span>
                                </b-col>
                            </b-row>

                            <b-row class="mt-2 view-row-cart-mobile" v-bind:key="option.id[index]"
                                v-for="(option, index) in formUpdateMaterial.id_barang_new">
                                <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="view-col-cart-mobile"
                                    style="align-self: center">
                                    <span class="my-name-material">{{ option.name }}</span>
                                </b-col>

                                <b-col cols="7" sm="7" md="8" lg="2" xl="2"
                                    class="position-stock-mobile view-col-cart-mobile" style="align-self: center">
                                    <template>
                                        <span class="my-stock">Stok: </span>
                                        <span> {{ option.stock }}</span>
                                    </template>
                                </b-col>

                                <b-col cols="5" sm="5" md="4" lg="3" xl="3"
                                    class="text-center d-flex position-quantity-mobile view-col-cart-mobile"
                                    style="align-self: center">
                                    <b-button class="d-inline-block" variant="outline-success"
                                        @click="incrementUpdateMaterial(index, option.stock)"><i
                                            class="fa fa-plus"></i></b-button>
                                    <b-form-input class="ml-1 mr-1 text-center" type="number"
                                        v-model="formUpdateMaterial.quantity_new[index]" :min="1" :max="option.stock"
                                        required></b-form-input>
                                    <b-button class="d-inline-block" variant="outline-success"
                                        @click="decrementUpdateMaterial(index)"><i class="fa fa-minus"></i></b-button>
                                </b-col>

                                <b-col cols="12" sm="12" md="1" lg="1" xl="1" class="text-center hide-action-remove"
                                    style="align-self: center">
                                    <b-button variant="outline-danger" @click="removeMaterialTemporary(index)">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <div class="text-right">
                        <b-button type="submit" variant="primary" class="btn-labeled" :disabled="lengthBarangNew < 1">
                            <span class="btn-label" v-show="!loadingUpdateMaterial"><i class="fa fa-save"></i></span>

                            <span v-show="loadingUpdateMaterial" class="btn-label">
                                <b-spinner small variant="light"></b-spinner>
                            </span>
                            Simpan Perubahan
                        </b-button>

                        <b-button variant="dark" class="ml-2 btn-labeled" @click="hideModal"><span class="btn-label"><i
                                    class="fa fa-times"></i></span>Tutup</b-button>
                    </div>
                </b-form>
            </template>
        </b-modal>
    </div>
</template>
  
<script>
import { validationMixin } from "/node_modules/vuelidate";

export default {
    mixins: [validationMixin],
    data: () => {
        return {
            initStatus: 0,
            initStatusDetail: 0,
            initStatusUpdateUserMaintenance: 0,
            initStatusUpdateMaterial: 0,
            loading: false,
            loadingUpdateMaterial: false,
            isBusy: true,
            collapse: false,
            collapseUpdate: false,
            typeMaintenanceSelected: null,
            vendorName: null,
            typeMaintenance: [
                {
                    value: null,
                    text: 'Pilih Type'
                },
                {
                    value: 'internal',
                    text: 'Internal'
                },
                {
                    value: 'vendor',
                    text: 'Vendor'
                }
            ],
            config: {
                format: "D MMM YYYY",
                useCurrent: true,
                minDate: null,
            },
            configFilter: {
                format: "D MMM YYYY",
                useCurrent: true,
            },
            fieldsMaterial: [
                {
                    key: "no",
                    label: "No",
                    thClass: "nameOfTheClassThNo",
                    tdClass: "nameOfTheClassTd",
                },
                {
                    key: "material",
                    label: "Material/Bahan",
                    thClass: "nameOfTheClassTh",
                },
                {
                    key: "quantity",
                    label: "Jumlah",
                    thClass: "nameOfTheClassThQuantity",
                    tdClass: "nameOfTheClassTd",
                },
            ],
            form: {
                id: null,
                noPengajuan: null,
                cabang: null,
                divisi: "",
                namaKategori: "",
                pengajuan: "",
                kuantitas: null,
                catatan: "",
                catatan_tolak: "",
                catatan_setuju: "",
                catatan_history: "",
                file: [],
                memo_maintenance: [],
                barang: [],
                barang_temp: [],
                estimasi: "",
                timerInProgress: null,
                timerFinish: null,
            },
            formUpdate: {
                id_memo: [],
                id_memo_recycle: "",
                date: "",
                memo_maintenance: [],
                memo_maintenance_temporary: [],
            },
            formUpdateMaterial: {
                id_memo: [],
                id_memo_recycle: "",
                id_barang: [],
                quantity: [],
                id_barang_new: [],
                quantity_new: [],
                status: [],
                cabang_kode: [],
                barang: [],
            },
            formDeleteMaterial: {
                id_memo: [],
                id_barang: [],
                cabang_kode: [],
            },
            formFilter: {
                from: null,
                until: null,
                selectFilter: null,
                selectJenisKategori: null,
                selectKategori: null,
                selectSubKategori: null,
                selectStatus: null,
                selectKabKot: null,
                selectCabang: [],
            },
            formMaintenance: {
                date: "",
                id_maintenance: [],
                id_memo: [],
                id_barang: [],
                quantity: [],
                pic: [],
                status: [],
            },
            formIgnore: {
                catatan_tolak: null,
            },
            optionsFilter: [
                {
                    value: "tanggal",
                    label: "Tanggal",
                },
                {
                    value: "cabang",
                    label: "Cabang",
                },
                {
                    value: "kabupaten-kota",
                    label: "Kabupaten/Kota",
                },
                {
                    value: "jenis-kategori",
                    label: "Jenis Pengajuan",
                },
                {
                    value: "kategori",
                    label: "Kategori Pengajuan",
                },
                {
                    value: "sub-kategori",
                    label: "Pengajuan",
                },
                {
                    value: "status",
                    label: "Status",
                },
            ],
            optionsStatus: [
                {
                    value: 0,
                    label: "Ditinjau Ulang",
                },
                {
                    value: 1,
                    label: "Disetujui 1",
                },
                {
                    value: 2,
                    label: "Disetujui 2",
                },
                {
                    value: 3,
                    label: "Diproses",
                },
                {
                    value: 11,
                    label: "Dijadwalkan Ulang",
                },
            ],
            persetujuan: [],
            detailPengajuan: [],
            getKategori: [],
            optionsCabang: [],
            optionsKabKot: [],
            optionsKategoriJenis: [],
            optionsSubKategori: [],
            optionsMaintenance: [],
            optionsMaterial: [],
            optionsMerek: [],
            optionsBarangTipe: [],
            historyMemo: [],
            getCabang: [],
            selected: [],
            idMemo: [],
            optionsChecked: [],
            cabangMaintenance: [],
            optionStatus1: [],
            optionStatus2: [],
            optionStatus3: [],
            indeksing: [],
            allSelected: false,
            approve1: "",
            approve2: "",
            approve3: "",
            rows: null,
            url: "",
            param: "",
            paramTipe: "",
            lengthMemoMaintenance: 0,
            lengthMaterial: 0,
            lengthUpdateMaterial: 0,
            lengthFindMaterial: 0,
            lengthBarangNew: 0,
            lengthNewUserMaintenance: 0,
            currentPage: 1,
            perPage: null,
            page: "",
            size: "",
            from: "",
            limit: "",
            query: "",
            findMaterial: "",
            showReason: false,
            loadCountDown: false,
            countDownToTime: null,
            timeDifference: null,
            calc: null,
            userId: localStorage.getItem("id"),
            katProses: JSON.parse(localStorage.getItem("k_proses"))[0],
        };
    },
    validations: {
        formIgnore: {
            catatan_tolak: {},
        },
    },

    //----------------- Fungsi Inisialisasi -----------------//
    methods: {
        init: function () {
            this.initStatus = 0;
            let getAllData = "";

            const today = new Date();
            this.config.minDate = today;

            // Get Main
            getAllData = this.$axios
                .get("api/internal-memo/memo")
                .then((response) => {
                    if (response.status === 200) {
                        console.log("DO PROCCES HERE...")
                    }
                });

            // Get User Maintenance
            getAllData = this.$axios
                .get("api/internal-memo/user-maintenance")
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;

                        for (let maintenance of datas) {
                            if (maintenance.is_active == 1) {
                                this.optionsMaintenance.push({
                                    id: maintenance.id,
                                    name: maintenance.nama,
                                    foto: maintenance.foto,
                                    status: maintenance.is_active,
                                });
                            }
                        }
                    }
                });

            // Get Cabang
            getAllData = this.$axios.get("api/admin/cabang/all").then((response) => {
                if (response.status == 200) {
                    let datas = response.data.data;

                    for (let cabang of datas) {
                        this.optionsCabang.push({
                            id: cabang.id,
                            name: cabang.name,
                            checked: false,
                        });
                    }
                }
            });

            // Get Kategori Jenis
            getAllData = this.$axios
                .get("api/internal-memo/kategori-jenis/all")
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;

                        for (let jenis of datas) {
                            this.optionsKategoriJenis.push({
                                id: jenis.id,
                                name: jenis.name,
                            });
                        }
                    }
                });

            // Get Sub Kategori
            getAllData = this.$axios
                .get("api/internal-memo/kategori-sub/all")
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;

                        for (let subKategori of datas) {
                            this.optionsSubKategori.push({
                                id: subKategori.id,
                                name: subKategori.name,
                            });
                        }
                    }
                });

            // Get Kabupaten/Kota
            getAllData = this.$axios
                .get("api/admin/wilayah/kabupaten")
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;

                        for (let kabkot of datas) {
                            this.optionsKabKot.push({
                                id: kabkot.id,
                                text: kabkot.name,
                            });
                        }
                    }
                });

            // Get Merk Barang
            getAllData = this.$axios
                .get("api/internal-memo/memo/barangMerk")
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;

                        for (let merek of datas) {
                            this.optionsMerek.push({
                                id: merek.id,
                                name: merek.merk,
                            });
                        }
                    }
                });

            // Get Material & Stock
            let param = {
                params: { kode_cabang: "0999" },
            };
            getAllData = this.$axios
                .get("api/internal-memo/memo/getStockBarangV2", param)
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;
                        let cls = "";

                        for (let type of datas.data) {
                            if (type.jumlah_stok <= 0) {
                                cls = "barang-card card-disabled";
                            } else {
                                cls = "barang-card";
                            }

                            this.optionsMaterial.push({
                                id: type.id,
                                id_tipe: type.id_tipe,
                                nama: type.barang_tipe.tipe,
                                satuan: type.satuan,
                                harga: type.harga,
                                stok: type.jumlah_stok,
                                detail: type.detail_barang,
                                pic: type.pic,
                                kelas: cls,
                            });
                        }

                        this.perPage = datas.per_page;
                        this.size = this.perPage;
                        this.from = datas.from;
                        this.limit = datas.to;

                        this.lengthFindMaterial = datas.total;
                        this.updateTotalItem(datas.total);
                    }
                });

            Promise.all([getAllData])
                .then(() => {
                    this.initStatus = 1;
                    this.toggleBusy();
                })
                .catch((error) => {
                    console.log(error);
                    this.initStatus = -1;
                    this.toggleBusy();
                });

            // Settings Options Status
            for (let option of this.optionsStatus) {
                if (option.value == 0 || option.value == 1 || option.value == 2) {
                    this.optionStatus1.push({
                        label: option.label,
                        value: option.value,
                    });
                }
                if (option.value == 1 || option.value == 2) {
                    this.optionStatus2.push({
                        label: option.label,
                        value: option.value,
                    });
                }

                if (option.value == 2 || option.value == 3 || option.value == 11) {
                    this.optionStatus3.push({
                        label: option.label,
                        value: option.value,
                    });
                }
            }
        },

        redirectSuratTugas: function () {
            console.log(this.$router.push('/'))
        },
        changeTypeMaintenance: function () {
            // BERSIHKAN KERANJANG JIKA SUDAH MASUKKAN
        },

        // Pagination, Sorting, dan Filtering List Material
        onPageChange: function () {
            if (this.loading == false) {
                this.page = this.currentPage;
                this.query = "?page=" + this.page;
                this.resultMaterial();
            }
        },

        toggleBusy: function () {
            if (this.isBusy == true) {
                this.isBusy = false;
            } else {
                this.isBusy = true;
            }
        },

        updateTotalItem: function (total) {
            this.perPage = this.size;
            this.rows = total;
        },

        // Function Collapse Animation
        collapseAction: function () {
            if (this.collapse === false) {
                this.collapse = true;
            } else {
                this.collapse = false;
            }
        },

        collapseUpdateAction: function () {
            if (this.collapseUpdate === false) {
                this.collapseUpdate = true;
            } else {
                this.collapseUpdate = false;
            }
        },

        // Function For Filter Cabang
        onSelect(option) {
            let index = this.optionsCabang.findIndex((item) => item.id == option.id);
            this.optionsCabang[index].checked = true;

            this.optionsChecked.push({
                id: option.id,
                checked: option.checked,
            });
        },

        onRemove(option) {
            let index = this.optionsCabang.findIndex((item) => item.id == option.id);
            this.optionsCabang[index].checked = false;
        },

        // Tag Multiselect Function
        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
            this.options.push(tag);
            this.value.push(tag);
        },

        // Function Reset Search
        onReset: function () {
            this.formFilter = {
                from: null,
                until: null,
                selectFilter: null,
                selectJenisKategori: null,
                selectKategori: null,
                selectSubKategori: null,
                selectKabKot: null,
                selectCabang: [],
                selectStatus: null,
            };

            for (var data of this.optionsChecked) {
                let index = this.optionsCabang.findIndex((item) => item.id == data.id);
                this.optionsCabang[index].checked = false;
            }

            this.resultFilter();
        },

        resetInputFilter: function () {
            this.formFilter.from = null;
            this.formFilter.until = null;
            this.formFilter.selectJenisKategori = null;
            this.formFilter.selectKategori = null;
            this.formFilter.selectSubKategori = null;
            this.formFilter.selectKabKot = null;
            this.formFilter.selectStatus = null;
            this.formFilter.selectCabang = [];

            for (var data of this.optionsChecked) {
                let index = this.optionsCabang.findIndex((item) => item.id == data.id);
                this.optionsCabang[index].checked = false;
            }

            this.resultFilter();
        },

        // Reset Find Material
        onResetMaterial: function () {
            this.findMaterial = "";
            this.resultMaterial();
        },

        // Hide Modal & Reset All Form Function
        hideModal: function () {
            this.$refs["my-modal"].hide();
            this.onResetAllForm();
        },

        onResetAllForm: function () {
            this.form = {
                id: null,
                noPengajuan: null,
                cabang: null,
                divisi: "",
                namaKategori: "",
                pengajuan: "",
                kuantitas: null,
                catatan: "",
                status: null,
                file: [],
                barang: [],
                barang_temp: [],
                memo_maintenance: [],
                estimasi: "",
                timerInProgress: null,
                timerFinish: null,
            };

            this.formUpdate = {
                id_memo: [],
                id_memo_recycle: "",
                date: "",
                memo_maintenance: [],
                memo_maintenance_temporary: [],
            };

            this.formUpdateMaterial = {
                id_memo: [],
                id_memo_recycle: "",
                id_barang: [],
                quantity: [],
                id_barang_new: [],
                quantity_new: [],
                cabang_kode: [],
                barang: [],
                status: [],
            };

            this.formDeleteMaterial = {
                id_memo: [],
                id_barang: [],
                cabang_kode: [],
            };

            this.formIgnore = {
                catatan_tolak: null,
            };

            this.historyMemo = [];
            this.findMaterial = "";
            this.countDownToTime = null;
            this.timeDifference = null;
            this.calc = null;
            this.lengthBarangNew = this.formUpdateMaterial.id_barang_new.length;
            this.lengthNewUserMaintenance = 0;
        },

        // Function Select All Approve
        selectAll: function () {
            this.allSelected = !this.allSelected;

            if (this.allSelected) {
                this.idMemo = [];
                this.formMaintenance.id_memo = [];

                for (let memo in this.persetujuan) {
                    if (
                        this.userId == this.katProses.user &&
                        this.katProses.proses == 1 &&
                        this.persetujuan[memo].status == 0
                    ) {
                        this.idMemo.push(this.persetujuan[memo].id);
                    }

                    if (
                        this.userId == this.katProses.user &&
                        this.katProses.proses == 2 &&
                        this.persetujuan[memo].status == 1
                    ) {
                        this.idMemo.push(this.persetujuan[memo].id);
                    }

                    if (
                        this.userId == this.katProses.user &&
                        this.katProses.proses == 3 &&
                        this.persetujuan[memo].status == 2
                    ) {
                        this.formMaintenance.id_memo.push(this.persetujuan[memo].id);
                    }
                }
            }
        },

        // Funtion Select Approve
        select: function () {
            this.allSelected = false;

            let arrCabang = [];
            let arrCabangOptions = [];

            for (let data of this.formMaintenance.id_memo) {
                let getCabang = this.persetujuan.findIndex((item) => item.id == data);
                let cabang = this.persetujuan[getCabang].id_cabang;

                arrCabang.push(cabang);
                if (arrCabang.indexOf(cabang) !== arrCabang.lastIndexOf(cabang)) {
                    arrCabang = [...new Set(arrCabang)];
                }
            }

            for (var cabang of this.optionsCabang) {
                for (var cabangSelect of arrCabang) {
                    if (cabangSelect == cabang.id) {
                        arrCabangOptions.push({
                            id: cabang.id,
                            name: cabang.name,
                        });
                    }
                }
            }
            this.cabangMaintenance = arrCabangOptions;
        },

        // Detail Function
        showModalDetail: function (id) {
            this.initStatusDetail = 0;

            let getDetail = this.$axios
                .get("api/internal-memo/memo/" + id)
                .then((response) => {

                    this.loadCountDown = true;
                    setInterval(() => {
                        this.loadCountDown = false;
                    }, 3000);

                    let datas = response.data.data;

                    let proses = "";
                    let number = 1;
                    let numberMaterial = 1;

                    this.form.id = datas.id;
                    this.form.noPengajuan = datas.im_number;
                    this.form.cabang = datas.cabang.name ? datas.cabang.name : "-";
                    this.form.divisi = datas.devisi.nm_Divisi;
                    this.form.pengajuan = datas.kategori_sub.name;
                    this.form.kuantitas = datas.qty ? datas.qty : "-";
                    this.form.prosesTerakhir = datas.list_history_memo;
                    this.form.catatan = datas.catatan;
                    this.form.catatan_tolak = datas.catatan_tolak;
                    this.form.catatan_setuju = datas.catatan_setuju;
                    this.form.status = datas.flag;
                    this.form.file = datas.memo_file;

                    for (let dataFile of this.form.file) {
                        if (
                            dataFile.flag === "foto_pic_ku" ||
                            dataFile.flag === "video_pic_ku"
                        ) {
                            this.buktiPekerjaan.push({
                                flag: dataFile.flag,
                                path: dataFile.path,
                            });
                        }
                    }

                    for (let dataHistory of datas.list_history_memo) {
                        let splitOneDigit = dataHistory.waktu_proses.split(":").pop();
                        let splitFrontDigit = dataHistory.waktu_proses.split(":")[0];

                        if (
                            splitOneDigit == "0" ||
                            splitOneDigit == "1" ||
                            splitOneDigit == "2" ||
                            splitOneDigit == "3" ||
                            splitOneDigit == "4" ||
                            splitOneDigit == "5" ||
                            splitOneDigit == "6" ||
                            splitOneDigit == "7" ||
                            splitOneDigit == "8" ||
                            splitOneDigit == "9"
                        ) {
                            proses = splitFrontDigit + ":" + "0" + splitOneDigit;
                        } else {
                            proses = splitFrontDigit + ":" + splitOneDigit;
                        }

                        this.historyMemo.push({
                            tanggal: dataHistory.created_at,
                            status: dataHistory.status,
                            keterangan: dataHistory.keterangan,
                            catatan: dataHistory.catatan,
                            waktu_proses: proses,
                        });
                    }

                    if (datas.barang) {
                        if (datas.barang.length >= 1) {
                            for (let x of datas.barang) {
                                this.form.barang.push({
                                    no: numberMaterial++,
                                    material: x.tipe,
                                    quantity: x.quantity,
                                });
                            }
                        }
                    }

                    for (let dataMemoMaintenance of datas.memo_maintenance) {
                        for (let dataUserMaintenance of dataMemoMaintenance.user_maintenance) {
                            this.form.memo_maintenance.push({
                                id: dataUserMaintenance.id,
                                no: number++,
                                tanggal: dataMemoMaintenance.date,
                                nama: dataUserMaintenance.nama,
                                no_telp: dataUserMaintenance.no_telp,
                                kode: dataMemoMaintenance.kode,
                                foto: dataUserMaintenance.foto,
                            });
                        }
                    }

                    let cekTimeByFlag = this.historyMemo.find(
                        (flag) => flag.status == 4 || flag.status == 10
                    );

                    this.form.namaKategori = datas.kategori_jenis.name;
                    this.form.estimasi = datas.kategori_sub.sla + " hari";

                    var createMemo = new Date(datas.created_at);
                    var millisecondsInOneSecond = 1000;
                    var millisecondsInOneMinute = millisecondsInOneSecond * 60;
                    var millisecondsInOneHour = millisecondsInOneMinute * 60;
                    var millisecondsInOneDay = millisecondsInOneHour * 24;

                    var differenceInDays = "";
                    var remainderDifferenceInHours = "";
                    var remainderDifferenceInMinutes = "";
                    var remainderDifferenceInSeconds = "";
                    var remainingDays = "";
                    var remainingHours = "";
                    var remainingMinutes = "";
                    var remainingSeconds = "";
                    var countDate = createMemo.setDate(
                        createMemo.getDate() + datas.kategori_sub.sla
                    );
                    var resultTime = new Date(countDate).getTime();
                    this.countDownToTime = resultTime;

                    if (datas.flag != 4 && datas.flag != 8 && datas.flag != 10) {
                        var countDown = setInterval(() => {
                            var timeNow = new Date().getTime();
                            var resultTimeOut = this.countDownToTime - timeNow;

                            differenceInDays = resultTimeOut / millisecondsInOneDay;
                            remainderDifferenceInHours =
                                (resultTimeOut % millisecondsInOneDay) / millisecondsInOneHour;
                            remainderDifferenceInMinutes =
                                (resultTimeOut % millisecondsInOneHour) /
                                millisecondsInOneMinute;
                            remainderDifferenceInSeconds =
                                (resultTimeOut % millisecondsInOneMinute) /
                                millisecondsInOneSecond;
                            remainingDays = Math.floor(differenceInDays);
                            remainingHours = Math.floor(remainderDifferenceInHours);
                            remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                            remainingSeconds = Math.floor(remainderDifferenceInSeconds);

                            if (remainingDays == 0) {
                                this.form.timerInProgress =
                                    remainingHours +
                                    ":" +
                                    remainingMinutes +
                                    ":" +
                                    remainingSeconds;
                            } else {
                                this.form.timerInProgress =
                                    remainingDays +
                                    " hari, " +
                                    remainingHours +
                                    ":" +
                                    remainingMinutes +
                                    ":" +
                                    remainingSeconds;
                            }

                            if (resultTimeOut < 0) {
                                var timeDifference = timeNow - this.countDownToTime;

                                differenceInDays = timeDifference / millisecondsInOneDay;
                                remainderDifferenceInHours =
                                    (timeDifference % millisecondsInOneDay) /
                                    millisecondsInOneHour;
                                remainderDifferenceInMinutes =
                                    (timeDifference % millisecondsInOneHour) /
                                    millisecondsInOneMinute;
                                remainderDifferenceInSeconds =
                                    (timeDifference % millisecondsInOneMinute) /
                                    millisecondsInOneSecond;
                                remainingDays = Math.floor(differenceInDays);
                                remainingHours = Math.floor(remainderDifferenceInHours);
                                remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                                remainingSeconds = Math.floor(remainderDifferenceInSeconds);

                                if (remainingDays == 0) {
                                    this.form.timerInProgress =
                                        "Lewat " +
                                        remainingHours +
                                        ":" +
                                        remainingMinutes +
                                        ":" +
                                        remainingSeconds;
                                } else {
                                    this.form.timerInProgress =
                                        "Lewat " +
                                        remainingDays +
                                        " hari, " +
                                        remainingHours +
                                        ":" +
                                        remainingMinutes +
                                        ":" +
                                        remainingSeconds;
                                }
                            }
                            this.timeDifference = resultTimeOut;
                        }, 1000);
                    } else {
                        clearInterval(countDown);

                        var getTimeByFlag = new Date(cekTimeByFlag.tanggal).getTime();
                        var calc = getTimeByFlag - resultTime;

                        differenceInDays = calc / millisecondsInOneDay;
                        remainderDifferenceInHours =
                            (calc % millisecondsInOneDay) / millisecondsInOneHour;
                        remainderDifferenceInMinutes =
                            (calc % millisecondsInOneHour) / millisecondsInOneMinute;
                        remainderDifferenceInSeconds =
                            (calc % millisecondsInOneMinute) / millisecondsInOneSecond;
                        remainingDays = Math.floor(differenceInDays);
                        remainingHours = Math.floor(remainderDifferenceInHours);
                        remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                        remainingSeconds = Math.floor(remainderDifferenceInSeconds);

                        this.calc = calc;
                        if (remainingDays == 0) {
                            this.form.timerFinish =
                                "Lewat " +
                                remainingHours +
                                ":" +
                                remainingMinutes +
                                ":" +
                                remainingSeconds;
                        } else {
                            this.form.timerFinish =
                                "Lewat " +
                                remainingDays +
                                " hari, " +
                                remainingHours +
                                ":" +
                                remainingMinutes +
                                ":" +
                                remainingSeconds;
                        }
                    }

                    this.lengthMaterial = datas.barang.length;
                    this.lengthMemoMaintenance = this.form.memo_maintenance.length;
                });



            Promise.all([getDetail])
                .then(() => {
                    this.initStatusDetail = 1;
                })
                .catch((error) => {
                    console.log(error);
                    this.initStatusDetail = -1;
                });

            this.$bvModal.show("m-detail");
        },

        // Function Approve All Memo
        approveAll: function () {
            this.$swal({
                title: "Konfirmasi Persetujuan",
                text: "Apakah anda yakin menyetujui pengajuan ini?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Saya menyetujui",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let memo_id = {
                        id: this.idMemo,
                    };

                    this.$swal({
                        title: "Mohon Ditunggu",
                        html: "Semua pengajuan sedang proses disetujui...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        willOpen: () => {
                            this.$swal.showLoading();
                        },
                    });

                    this.isSubmitting = true;
                    this.$axios
                        .post("/api/internal-memo/memo/accMemoAll", memo_id)
                        .then((response) => {
                            if (response.status == 200) {
                                this.$swal({
                                    title: "Sukses!",
                                    text: "Pengajuan berhasil disetujui",
                                    // icon: "success",
                                    iconHtml: `
                                        <div class="m-2">
                                        <i class="fa fa-check"></i>
                                        </div>
                                    `,
                                    iconColor: '#a5dc86',
                                    showConfirmButton: false,
                                    timer: 2500,
                                });
                            }
                            this.hideModal();
                            this.resultFilter();
                        })
                        .catch((error) => {
                            this.$swal({
                                title: "Gagal",
                                html: "Pengajuan gagal disetujui. <br> (" + error + ")",
                                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                                iconColor: '#dc3545',
                                showConfirmButton: false,
                                timer: 2500,
                            });
                        })
                        .finally(() => (this.isSubmitting = false));
                }
            });
        },

        // Function Approve Memo
        sweetAlertApprove: function () {
            this.$swal({
                title: "Konfirmasi Persetujuan",
                text: "Apakah anda yakin menyetujui pengajuan ini?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Saya menyetujui",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.form.id,
                        catatan: this.form.catatan_history
                    };

                    this.$swal({
                        title: "Mohon Menunggu",
                        html: "Pengajuan ini sedang diproses...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        willOpen: () => {
                            this.$swal.showLoading();
                        },
                    });

                    this.isSubmitting = true;
                    this.$axios
                        .post("/api/internal-memo/memo/acc/" + this.form.id, form)
                        .then((response) => {
                            if (response.status == 200) {
                                this.$swal({
                                    title: "Sukses!",
                                    text: "Pengajuan berhasil disetujui",
                                    // icon: "success",
                                    iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                                    iconColor: '#a5dc86',
                                    showConfirmButton: false,
                                    timer: 2500,
                                });
                            }
                            this.hideModal();
                            this.resultFilter();
                            this.$bvModal.hide("m-detail");
                        })
                        .catch((error) => {
                            this.$swal({
                                title: "Gagal",
                                html: "Pengajuan gagal disetujui. <br> (" + error + ")",
                                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                                iconColor: '#dc3545',
                                showConfirmButton: false,
                                timer: 2500,
                            });
                        })
                        .finally(() => (this.isSubmitting = false));
                }
            });
        },

        // Function Ignore Memo
        onConfirmIgnore: function () {
            this.$v.formIgnore.$touch();

            if (this.$v.formIgnore.$invalid) {
                return;
            } else {
                this.sweetAlertReject();
            }
        },

        sweetAlertReject: function () {
            this.$swal({
                title: "Konfirmasi Persetujuan",
                text: "Apakah anda yakin menolak pengajuan ini?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Saya tolak",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    let formIgnore = {
                        catatan: this.form.catatan
                    };
                    this.$swal({
                        title: "Mohon Menunggu",
                        html: "Pengajuan ini sedang diproses...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        willOpen: () => {
                            this.$swal.showLoading();
                        },
                    });

                    this.isSubmitting = true;
                    this.$axios
                        .post("/api/internal-memo/memo/ignore/" + this.form.id, formIgnore)
                        .then((response) => {
                            if (response.status == 200) {
                                this.$swal({
                                    title: "Sukses!",
                                    text: "Pengajuan berhasil ditolak",
                                    // icon: "success",
                                    iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                                    iconColor: '#a5dc86',
                                    showConfirmButton: false,
                                    timer: 2500,
                                });
                            }
                            this.$bvModal.hide("m-detail");
                            this.formIgnore.catatan_tolak = null;
                            this.resultFilter();
                        })
                        .catch((error) => {
                            this.$swal({
                                title: "Gagal",
                                html: "Pengajuan gagal ditolak. <br> (" + error + ")",
                                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                                iconColor: '#dc3545',
                                showConfirmButton: false,
                                timer: 2500,
                            });
                        })
                        .finally(() => (this.isSubmitting = false));
                }
            });
        },

        // Result Filter
        resultFilter: function (value) {
            this.toggleBusy();

            if (this.formFilter.selectJenisKategori) {
                this.url =
                    "api/internal-memo/memo?id_kategori_jenis_fpp=" +
                    this.formFilter.selectJenisKategori;
            } else if (this.formFilter.selectKategori) {
                this.url =
                    "api/internal-memo/memo?id_kategori_fpp=" +
                    this.formFilter.selectKategori;
            } else if (this.formFilter.selectSubKategori) {
                this.url =
                    "api/internal-memo/memo?id_kategori_sub_fpp=" +
                    this.formFilter.selectSubKategori;
            } else if (this.formFilter.from && this.formFilter.until) {
                this.url =
                    "api/internal-memo/memo?startDate=" +
                    this.formFilter.from +
                    "&endDate=" +
                    this.formFilter.until;
            } else if (this.formFilter.selectStatus) {
                this.url =
                    "api/internal-memo/memo?flag=" + this.formFilter.selectStatus;
            } else if (this.formFilter.selectKabKot) {
                this.url =
                    "api/internal-memo/memo?kabupaten_kota_id=" +
                    this.formFilter.selectKabKot;
            } else if (this.formFilter.selectCabang) {
                if (value) {
                    let arr = [];

                    for (var data of value) {
                        arr.push(data.id);
                    }

                    this.param = {
                        params: { id_cabang_multiple: arr },
                    };
                }
                this.url = "api/internal-memo/memo";
            } else {
                this.url = "api/internal-memo/memo";
            }

            this.$axios
                .get(this.url, value ? this.param : "")
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;
                        let jenisPengajuan = "";
                        let arrApprove1 = [];
                        let arrApprove2 = [];
                        let arrApprove3 = [];

                        this.toggleBusy();
                        this.persetujuan = [];

                        for (let data of datas) {
                            if (data.kategori_sub == null) {
                                data.kategori_sub = { name: "-" };
                            }

                            jenisPengajuan =
                                data.kategori_jenis.name + " " + data.kategori_sub.name;

                            if (this.katProses) {
                                if (this.userId == this.katProses.user) {
                                    if (this.katProses.proses == 1) {
                                        if (data.flag == 0) {
                                            arrApprove1.push(data.im_number);
                                        }

                                        if (data.flag == 0 || data.flag == 1 || data.flag == 2) {
                                            this.persetujuan.push({
                                                id: data.id,
                                                id_cabang: data.cabang.id,
                                                cabang: data.cabang.name,
                                                kabkot: data.cabang.kabupaten_kota.name,
                                                jenis_pengajuan: jenisPengajuan,
                                                catatan: data.catatan,
                                                tanggal: data.created_at,
                                                status: data.flag,
                                                no_pengajuan: data.im_number,
                                            });
                                        }
                                    } else if (this.katProses.proses == 2) {
                                        if (data.flag == 1) {
                                            arrApprove2.push(data.im_number);
                                        }

                                        if (data.flag == 1 || data.flag == 2) {
                                            this.persetujuan.push({
                                                id: data.id,
                                                id_cabang: data.cabang.id,
                                                cabang: data.cabang.name,
                                                kabkot: data.cabang.kabupaten_kota.name,
                                                jenis_pengajuan: jenisPengajuan,
                                                catatan: data.catatan,
                                                tanggal: data.created_at,
                                                status: data.flag,
                                                no_pengajuan: data.im_number,
                                            });
                                        }
                                    } else if (this.katProses.proses == 3) {
                                        if (data.flag == 2) {
                                            arrApprove3.push(data.im_number);
                                        }

                                        if (data.flag == 2 || data.flag == 3 || data.flag == 11) {
                                            this.persetujuan.push({
                                                id: data.id,
                                                id_cabang: data.cabang.id,
                                                cabang: data.cabang.name,
                                                kabkot: data.cabang.kabupaten_kota.name,
                                                jenis_pengajuan: jenisPengajuan,
                                                catatan: data.catatan,
                                                tanggal: data.created_at,
                                                status: data.flag,
                                                no_pengajuan: data.im_number,
                                            });
                                        }
                                    }
                                }
                            }
                        }

                        this.approve1 = arrApprove1.length;
                        this.approve2 = arrApprove2.length;
                        this.approve3 = arrApprove3.length;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.toggleBusy();
                });
        },

        // Result Material
        resultMaterial: function () {
            if (this.findMaterial != "") {
                this.loading = true;

                let param = {
                    params: { kode_cabang: "0999", tipe: this.findMaterial },
                };

                this.$axios
                    .get("api/internal-memo/memo/getStockBarangV2" + this.query, param)
                    .then((response) => {
                        let datas = response.data.data;
                        let cls = "";

                        this.optionsMaterial = [];

                        for (let type of datas.data) {
                            if (type.jumlah_stok <= 0) {
                                cls = "barang-card card-disabled";
                            } else {
                                cls = "barang-card";
                            }

                            this.optionsMaterial.push({
                                id: type.id,
                                id_tipe: type.id_tipe,
                                nama: type.barang_tipe.tipe,
                                satuan: type.satuan,
                                harga: type.harga,
                                stok: type.jumlah_stok,
                                detail: type.detail_barang,
                                pic: type.pic,
                                kelas: cls,
                            });
                        }

                        this.perPage = datas.per_page;
                        this.size = this.perPage;
                        this.from = datas.from;
                        this.limit = datas.to;

                        this.loading = false;
                        this.lengthFindMaterial = datas.total;
                        this.updateTotalItem(datas.total);
                    })
                    .catch((error) => {
                        this.$helper.parseError(this, error);
                        this.toggleBusy();
                        this.loading = false;
                    });
            } else {
                this.loading = true;

                let param = {
                    params: { kode_cabang: "0999" },
                };

                this.$axios
                    .get("api/internal-memo/memo/getStockBarangV2" + this.query, param)
                    .then((response) => {
                        let datas = response.data.data;
                        let cls = "";

                        this.optionsMaterial = [];

                        for (let type of datas.data) {
                            if (type.jumlah_stok <= 0) {
                                cls = "barang-card card-disabled";
                            } else {
                                cls = "barang-card";
                            }

                            this.optionsMaterial.push({
                                id: type.id,
                                id_tipe: type.id_tipe,
                                nama: type.barang_tipe.tipe,
                                satuan: type.satuan,
                                harga: type.harga,
                                stok: type.jumlah_stok,
                                detail: type.detail_barang,
                                pic: type.pic,
                                kelas: cls,
                            });
                        }

                        this.perPage = datas.per_page;
                        this.size = this.perPage;
                        this.from = datas.from;
                        this.limit = datas.to;

                        this.loading = false;
                        this.lengthFindMaterial = datas.total;
                        this.updateTotalItem(datas.total);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.toggleBusy();
                        this.loading = false;
                    });
            }
        },

        // ============================= Funtion Create Material Memo ============================= //

        // Increment Quantity Material
        increment: function (index, stock) {
            if (this.formMaintenance.quantity[index] < stock) {
                let inc = parseInt(this.formMaintenance.quantity[index]) + 1;
                this.formMaintenance.quantity.splice(index, 1, inc);
            } else {
                const Toast = this.$swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", this.$swal.stopTimer);
                        toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                });
                Toast.fire({
                    title: "Perhatian!",
                    icon: "warning",
                    html: "<small class='text-danger'>Jumlah material tambahan tidak bisa melebihi stok yang tersedia!</small>",
                });
            }
        },

        // Decrement Quantity Material
        decrement: function (index) {
            let minInput = 1;
            if (this.formMaintenance.quantity[index] > minInput) {
                let dec = parseInt(this.formMaintenance.quantity[index]) - 1;
                this.formMaintenance.quantity.splice(index, 1, dec);
            } else {
                this.removeBarang(index);
            }
        },

        // Remove Material Temporary
        removeBarang: function (index) {
            this.formMaintenance.id_barang.splice(index, 1);
            this.formMaintenance.quantity.splice(index, 1);
            this.formMaintenance.pic.splice(index, 1);
        },

        // Add Material Function
        addToFormMaintenance: function (id, nama, stok, pic) {
            var defaultQuantity = 1;

            var Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });

            if (this.formMaintenance.id_barang.length > 0) {
                let checkId = this.formMaintenance.id_barang.find(
                    (item) => item.id === id
                );

                if (checkId) {
                    let checkIndeks = (element) => element.id === id;
                    let indeks = this.formMaintenance.id_barang.findIndex(checkIndeks);

                    if (this.formMaintenance.quantity[indeks] < stok) {
                        let lastQuantity =
                            parseInt(this.formMaintenance.quantity[indeks]) + defaultQuantity;

                        this.formMaintenance.quantity.splice(indeks, 1, lastQuantity);
                        Toast.fire({
                            title: "Selamat!",
                            // icon: "success",
                            iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                            iconColor: '#a5dc86',
                            html:
                                "<small class='font-weight-bold'>" +
                                lastQuantity +
                                " Material " +
                                nama +
                                " </small><br /><small>berhasil ditambahkan ke dalam keranjang</small>",
                        });
                    } else {
                        Toast.fire({
                            title: "Perhatian!",
                            icon: "warning",
                            html: "<small class='text-danger'>Jumlah material tambahan tidak bisa melebihi dari stok yang tersedia!</small>",
                        });
                    }
                } else {
                    this.formMaintenance.id_barang.push({
                        id: id,
                        name: nama,
                        stock: stok,
                    });
                    this.formMaintenance.quantity.push(defaultQuantity);
                    this.formMaintenance.pic.push(pic);
                    this.formMaintenance.status.push({
                        status: true,
                    });
                    Toast.fire({
                        title: "Selamat!",
                        // icon: "success",
                        iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                        iconColor: '#a5dc86',
                        html:
                            "<small class='font-weight-bold'>" +
                            defaultQuantity +
                            " Material " +
                            nama +
                            " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
                    });
                }
            } else {
                this.formMaintenance.id_barang.push({
                    id: id,
                    name: nama,
                    stock: stok,
                });
                this.formMaintenance.quantity.push(defaultQuantity);
                this.formMaintenance.pic.push(pic);
                this.formMaintenance.status.push({
                    status: true,
                });
                Toast.fire({
                    title: "Selamat!",
                    // icon: "success",
                    iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                    iconColor: '#a5dc86',
                    html:
                        "<small class='font-weight-bold'>" +
                        defaultQuantity +
                        " Material " +
                        nama +
                        " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
                });
            }
            this.lengthBarangID = this.formMaintenance.id_barang.length;
        },

        // Confirm Surat Tugas Function
        onConfirm: function () {
            this.onSubmitMaintenance();
        },

        // Submit Surat Tugas Function
        onSubmitMaintenance: function () {
            let arrMaintenance = [];
            let arrMemo = [];
            let arrBarang = [];
            let arrQty = [];
            let arrPic = [];

            for (var maintenance of this.formMaintenance.id_maintenance) {
                arrMaintenance.push(maintenance.id);
            }

            for (var memo of this.formMaintenance.id_memo) {
                arrMemo.push(memo);
            }

            for (var barang of this.formMaintenance.id_barang) {
                arrBarang.push(barang.id);
            }

            for (var qty of this.formMaintenance.quantity) {
                arrQty.push(parseInt(qty));
            }

            for (let pic of this.formMaintenance.pic) {
                arrPic.push(pic);
            }

            const formatDate = new Date(this.formMaintenance.date);
            let resultDate = this.$moment(formatDate).format("YYYY-MM-D");
            var param = "";

            if (arrBarang.length < 1) {
                param = {
                    date: resultDate,
                    id_user_maintenance: arrMaintenance,
                    id_memo: arrMemo,
                };
            } else {
                param = {
                    date: resultDate,
                    id_user_maintenance: arrMaintenance,
                    id_memo: arrMemo,
                    id_barang: arrBarang,
                    quantity: arrQty,
                    pic: arrPic,
                };
            }

            this.$swal({
                title: "Konfirmasi",
                text: "Apakah anda yakin menambahkan surat tugas ini?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Saya menyetujui",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        title: "Mohon Ditunggu",
                        html: "Pembuatan surat tugas sedang diproses...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        willOpen: () => {
                            this.$swal.showLoading();
                        },
                    });

                    this.isSubmitting = true;
                    this.$axios
                        .post("/api/internal-memo/memo/newInternalMaintenance/test", param)
                        .then((response) => {
                            if (response.status == 200) {
                                this.$swal({
                                    title: "Sukses!",
                                    text: "Pembuatan Surat tugas berhasil.",
                                    // icon: "success",
                                    iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                                    iconColor: '#a5dc86',
                                    showConfirmButton: false,
                                    timer: 2500,
                                });
                            }

                            this.resultFilter();
                            this.resultMaterial();

                            this.formMaintenance.date = "";
                            this.formMaintenance.id_maintenance = [];
                            this.formMaintenance.id_memo = [];
                            this.formMaintenance.id_barang = [];
                            this.formMaintenance.quantity = [];
                            this.formMaintenance.pic = [];
                        })
                        .catch((error) => {
                            this.$swal({
                                title: "Gagal",
                                html: "Pembuatan Surat tugas gagal!. <br> (" + error + ")",
                                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                                iconColor: '#dc3545',
                                showConfirmButton: false,
                                timer: 2500,
                            });
                        })
                        .finally(() => (this.isSubmitting = false));
                }
            });
        },

        // ============================= Funtion Resechedule Memo For Update ============================= //

        // Add Material To Cart For Update
        addMaterialMaintenance: function (id, nama, stok, pic) {
            var defaultQuantity = 1;

            var Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });

            if (this.formUpdateMaterial.id_barang_new.length > 0) {
                let checkId = this.formUpdateMaterial.id_barang_new.find(
                    (item) => item.id === id
                );

                if (checkId) {
                    let checkIndeks = (element) => element.id === id;
                    let indeks =
                        this.formUpdateMaterial.id_barang_new.findIndex(checkIndeks);
                    if (this.formUpdateMaterial.quantity_new[indeks] < stok) {
                        let lastQuantity =
                            parseInt(this.formUpdateMaterial.quantity_new[indeks]) +
                            defaultQuantity;
                        this.formUpdateMaterial.quantity_new.splice(
                            indeks,
                            1,
                            lastQuantity
                        );
                        Toast.fire({
                            title: "Selamat!",
                            // icon: "success",
                            iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                            iconColor: '#a5dc86',
                            html:
                                "<small class='font-weight-bold'>(" +
                                lastQuantity +
                                ") Material " +
                                nama +
                                " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
                        });
                    } else {
                        Toast.fire({
                            title: "Perhatian!",
                            icon: "warning",
                            html: "<small class='text-danger'>Jumlah material tambahan tidak bisa melebihi dari stok yang tersedia!</small>",
                        });
                    }
                } else {
                    this.formUpdateMaterial.id_barang_new.push({
                        id: id,
                        name: nama,
                        stock: stok,
                    });
                    this.formUpdateMaterial.quantity_new.push(defaultQuantity);
                    this.formUpdateMaterial.cabang_kode.push(pic);
                    this.formUpdateMaterial.status.push({
                        status: true,
                    });
                    Toast.fire({
                        title: "Selamat!",
                        // icon: "success",
                        iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                        iconColor: '#a5dc86',
                        html:
                            "<small class='font-weight-bold'>(" +
                            defaultQuantity +
                            ") Material " +
                            nama +
                            " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
                    });
                }
            } else {
                this.formUpdateMaterial.id_barang_new.push({
                    id: id,
                    name: nama,
                    stock: stok,
                });
                this.formUpdateMaterial.quantity_new.push(defaultQuantity);
                this.formUpdateMaterial.cabang_kode.push(pic);
                this.formUpdateMaterial.status.push({
                    status: true,
                });
                Toast.fire({
                    title: "Selamat!",
                    // icon: "success",
                    iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                    iconColor: '#a5dc86',
                    html:
                        "<small class='font-weight-bold'>(" +
                        defaultQuantity +
                        ") Material " +
                        nama +
                        " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
                });
            }
            this.lengthBarangNew = this.formUpdateMaterial.id_barang_new.length;
        },

        // Remove Material Live For Update
        removeMaterialLive: function (
            id_barang,
            index,
            qty,
            material,
            cabang_kode
        ) {
            var Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });

            this.formDeleteMaterial.id_barang.push(id_barang);
            this.formDeleteMaterial.cabang_kode.push(cabang_kode.toString());
            this.formDeleteMaterial.id_memo.push(
                this.formUpdateMaterial.id_memo_recycle
            );

            let param = {
                id_memo: this.formDeleteMaterial.id_memo,
                id_barang: this.formDeleteMaterial.id_barang,
                cabang_kode: this.formDeleteMaterial.cabang_kode,
            };

            this.$axios
                .post("/api/internal-memo/memo/deleteBarangMaintenance", param)
                .then((response) => {
                    if (response.status == 200) {
                        Toast.fire({
                            title: "Selamat!",
                            // icon: "success",
                            iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                            iconColor: '#a5dc86',
                            html:
                                "<small class='font-weight-bold'>(" +
                                qty +
                                ") Material " +
                                material +
                                " </small><small>berhasil dihapus dari keranjang</small>",
                        });
                        this.formUpdateMaterial.barang.splice(index, 1);
                        this.formUpdateMaterial.quantity.splice(index, 1);
                        this.formUpdateMaterial.cabang_kode.splice(index, 1);

                        this.resultMaterial();
                        this.formDeleteMaterial.id_barang = [];
                        this.formDeleteMaterial.cabang_kode = [];
                        this.lengthUpdateMaterial = this.formUpdateMaterial.barang.length;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    Toast.fire({
                        title: "Gagal!",
                        iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                        iconColor: '#dc3545',
                        html: "<small>Material gagal dihapus!</small>",
                    });
                });
        },

        // Remove Material Temporary For Update
        removeMaterialTemporary: function (index) {
            this.formUpdateMaterial.id_barang_new.splice(index, 1);
            this.formUpdateMaterial.quantity_new.splice(index, 1);
            this.formUpdateMaterial.cabang_kode.splice(index, 1);
            this.lengthBarangNew = this.formUpdateMaterial.id_barang_new.length;
        },

        // Increment Quantity Material
        incrementUpdateMaterial: function (index, stock) {
            if (this.formUpdateMaterial.quantity_new[index] < stock) {
                let inc = parseInt(this.formUpdateMaterial.quantity_new[index]) + 1;
                this.formUpdateMaterial.quantity_new.splice(index, 1, inc);
            } else {
                const Toast = this.$swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", this.$swal.stopTimer);
                        toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                });
                Toast.fire({
                    title: "Perhatian!",
                    icon: "warning",
                    html: "<small class='text-danger font-weight-bold'>Jumlah material tambahan tidak bisa melebihi stok yang tersedia!</small>",
                });
            }
        },

        // Decrement Quantity Material
        decrementUpdateMaterial: function (index) {
            let minInput = 1;
            if (this.formUpdateMaterial.quantity_new[index] > minInput) {
                let dec = parseInt(this.formUpdateMaterial.quantity_new[index]) - 1;
                this.formUpdateMaterial.quantity_new.splice(index, 1, dec);
            } else {
                this.removeMaterialTemporary(index);
            }
        },

        // Show User Maintenance Existing
        showDataMaintenance: function (id) {
            let arrMaintenance = [];

            for (let data of id) {
                this.$axios.get("api/internal-memo/memo/" + data).then((response) => {
                    let datas = response.data.data;

                    for (let data of datas.memo_maintenance) {
                        arrMaintenance.push(data.id_user_maintenance);
                    }
                });
            }
        },

        // Remove User Maintenance Function
        toggleUnSelectMarket: function (value) {
            let arrUserMaintenance = [];
            let idDetail = this.formUpdate.id_memo_recycle;

            this.loading = true;

            arrUserMaintenance.push(value.id);

            let param = {
                id_memo: this.formUpdate.id_memo,
                id_user_maintenance: arrUserMaintenance,
            };
            this.$axios
                .post("/api/internal-memo/memo/deleteUserMaintenance", param)
                .then((response) => {
                    if (response.status == 200) {
                        this.formUpdate.id_memo = [];
                        this.formUpdate.id_memo_recycle = null;
                        this.formUpdate.memo_maintenance_temporary = [];
                        this.formUpdate.memo_maintenance = [];
                        this.formUpdate.date = "";
                        this.lengthNewUserMaintenance = 0;

                        this.showModalUpdateUserMaintenance(idDetail);
                    }
                });
        },

        // Modal Changes User Maintenance
        showModalUpdateUserMaintenance: function (id) {
            this.loading = false;
            this.initStatusUpdateUserMaintenance = 0;
            this.lengthNewUserMaintenance = 0;
            this.formUpdate.id_memo_recycle = id;
            this.formUpdate.id_memo.push(id);

            let getUsermaintenance = this.$axios
                .get("api/internal-memo/memo/" + id)
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;

                        for (let data of datas.memo_maintenance) {
                            for (let user of data.user_maintenance) {
                                this.formUpdate.memo_maintenance.push({
                                    id: user.id,
                                    name: user.nama,
                                });

                                const formatDate = new Date(data.date);
                                this.formUpdate.date = formatDate;

                                this.formUpdate.memo_maintenance_temporary.push({
                                    id: user.id,
                                    name: user.nama,
                                });
                            }
                        }
                    }
                });

            Promise.all([getUsermaintenance])
                .then(() => {
                    this.initStatusUpdateUserMaintenance = 1;
                })
                .catch((error) => {
                    console.log(error);
                    this.initStatusUpdateUserMaintenance = -1;
                });
            this.$bvModal.show("m-update-user-maintenance");
        },

        // Check Length New User Maintenance
        checkValueUserMaintenance: function () {
            this.lengthNewUserMaintenance =
                this.formUpdate.memo_maintenance_temporary.length;
        },

        // Submit Changer User Maintenance & Date
        onConfirmUpdateMaintenance: function () {
            let arrMaintenance = [];
            let arrMaintenanceTemp = [];

            for (let temp of this.formUpdate.memo_maintenance_temporary) {
                arrMaintenanceTemp.push(temp.id);
            }
            for (let data of this.formUpdate.memo_maintenance) {
                arrMaintenance.push(data.id);
            }

            var final = arrMaintenance.filter(function (item) {
                for (var i = 0; i < arrMaintenanceTemp.length; i++) {
                    if (arrMaintenanceTemp[i] === item) return false;
                }
                return true;
            });

            var Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
            });

            let param = "";
            let idDetail = this.formUpdate.id_memo_recycle;

            if (final.length > 0) {
                param = {
                    id_memo: this.formUpdate.id_memo,
                    id_user_maintenance: final,
                };
            } else {
                param = {
                    id_memo: this.formUpdate.id_memo,
                    id_user_maintenance: arrMaintenance,
                    date: this.formUpdate.date,
                };
            }

            this.loading = true;

            this.$axios
                .post("/api/internal-memo/memo/createUserMaitenance", param)
                .then((response) => {
                    if (response.status == 200) {
                        Toast.fire({
                            title: "Sukses!",
                            // icon: "success",
                            iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                            iconColor: '#a5dc86',
                            html: "<small>Perubahan berhasil.</small>",
                            showCancelButton: true,
                            showConfirmButton: true,
                            confirmButtonColor: "#1e73be",
                            cancelButtonText: "Nanti",
                            confirmButtonText: "Lihat Perubahan",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.showModalDetail(idDetail);
                            } else {
                                this.onResetAllForm();
                            }
                        });

                        this.loading = false;
                        this.formUpdate.id_memo = [];
                        this.formUpdate.memo_maintenance = [];
                        this.formUpdate.date = "";
                        this.lengthNewUserMaintenance = 0;

                        this.$bvModal.hide("m-update-user-maintenance");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    Toast.fire({
                        title: "Gagal!",
                        iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                        iconColor: '#dc3545',
                        html: "<small>Perubahan gagal!</small>",
                    });
                });
        },

        // Modal Changes Material
        showModalUpdateMaterial: function (id) {
            this.initStatusUpdateMaterial = 0;
            this.formUpdateMaterial.id_memo.push(id);
            this.formUpdateMaterial.id_memo_recycle = id;

            let getMaterial = this.$axios
                .get("api/internal-memo/memo/" + id)
                .then((response) => {
                    if (response.status == 200) {
                        let datas = response.data.data;

                        if (datas.barang.length >= 1) {
                            for (let data of datas.barang) {
                                this.formUpdateMaterial.barang.push({
                                    id: data.id,
                                    id_tipe: data.id_tipe,
                                    id_barang: data.id_barang,
                                    tipe: data.tipe,
                                    quantity: data.quantity,
                                    pic: data.pic,
                                    loading: false,
                                });
                            }
                        }

                        this.lengthUpdateMaterial = this.formUpdateMaterial.barang.length;
                    }
                });

            Promise.all([getMaterial])
                .then(() => {
                    this.initStatusUpdateMaterial = 1;
                })
                .catch((error) => {
                    console.log(error);
                    this.initStatusUpdateMaterial = -1;
                });

            this.$bvModal.show("m-update-material");
        },

        // Submit Changes Material
        swtUpdateMaterial: function () {
            let arrMemo = [];
            let arrBarang = [];
            let arrQty = [];
            let arrCabangKode = [];

            for (let memo of this.formUpdateMaterial.id_memo) {
                arrMemo.push(memo);
            }
            for (let barang of this.formUpdateMaterial.id_barang_new) {
                arrBarang.push(barang.id);
            }
            for (let qty of this.formUpdateMaterial.quantity_new) {
                arrQty.push(parseInt(qty));
            }
            for (let kode of this.formUpdateMaterial.cabang_kode) {
                arrCabangKode.push(kode);
            }

            if (arrBarang.length > 0) {
                let param = {
                    id_memo: arrMemo,
                    id_barang: arrBarang,
                    quantity: arrQty,
                    cabang_kode: arrCabangKode,
                };

                var Toast = this.$swal.mixin({
                    toast: true,
                    position: "top-end",
                });

                this.loadingUpdateMaterial = true;

                this.$axios
                    .post("/api/internal-memo/memo/createBarangMaintenance", param)
                    .then((response) => {
                        if (response.status == 200) {
                            let idDetail = this.formUpdateMaterial.id_memo_recycle;

                            Toast.fire({
                                title: "Sukses!",
                                // icon: "success",
                                iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                                iconColor: '#a5dc86',
                                html: "<small>Perubahan material/bahan berhasil.</small>",
                                showCancelButton: true,
                                showConfirmButton: true,
                                confirmButtonColor: "#1e73be",
                                cancelButtonText: "Nanti",
                                confirmButtonText: "Lihat Perubahan",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    // this.showModalUpdateMaterial(idDetail);
                                    this.showModalDetail(idDetail);
                                } else {
                                    // this.hideModal();
                                    this.onResetAllForm();
                                }
                            });

                            this.loadingUpdateMaterial = false;
                            this.findMaterial = "";
                            this.lengthBarangNew = 0;
                            this.formUpdateMaterial.id_memo = [];
                            this.formUpdateMaterial.id_barang = [];
                            this.formUpdateMaterial.quantity = [];
                            this.formUpdateMaterial.barang = [];
                            this.formUpdateMaterial.id_barang_new = [];
                            this.formUpdateMaterial.quantity_new = [];
                            this.formUpdateMaterial.cabang_kode = [];
                            this.formUpdateMaterial.status = [];

                            this.resultMaterial();
                            this.$bvModal.hide("m-update-material");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loadingUpdateMaterial = false;
                        Toast.fire({
                            title: "Gagal!",
                            iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                            iconColor: '#dc3545',
                            html: "<small>Perubahan material/bahan gagal!</small>",
                        });
                    });
            }
        },
    },

    created() {
        this.init();
    },
};
</script>
  
<style lang="scss">
span {
    font-size: unset;
}

.required label::after {
    content: "*";
    color: #f79483;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.b-table-empty-row {
    text-align: center;
}

.table th,
.table td {
    vertical-align: middle;
}

.nameOfTheClassTh {
    text-align: center;
}

.nameOfTheClassThNo {
    width: 50px;
    text-align: center;
}

.nameOfTheClassThSelect {
    width: 5%;
    text-align: center;
}

.nameOfTheClassThHP {
    width: 15%;
    text-align: center;
}

.nameOfTheClassThFoto {
    width: 12%;
    text-align: center;
}

.nameOfTheClassThQuantity {
    width: 12%;
    text-align: center;
}

.nameOfTheClassThAction {
    width: 8%;
    text-align: center;
}

.nameOfTheClassThTanggal {
    width: 18%;
    text-align: center;
}

.nameOfTheClassTd {
    text-align: center;
}

.my-iframe {
    width: 250px;
    height: 250px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn-upload {
    padding: 8px 20px;
    background-color: #808080;
    border: none;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    transition: 100ms ease-out;
    cursor: pointer;
}

.upload-btn-wrapper:hover {
    border-radius: 5px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.btn-label {
    position: relative;
    left: -12px;
    display: inline-block;
    padding: 6px 12px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem 0 0 0.25rem;
}

.btn-labeled {
    padding-top: unset;
    padding-bottom: unset;
}

.show-icon {
    display: none;
}

.multiselect {
    width: 100%;
}

.my-multiselect {
    width: auto;
    display: inline-block;
    vertical-align: middle;
}

.multiselect__tag {
    background: #1e73be;
    color: #fff;
}

.option__desc,
.option__image {
    display: inline-block;
    vertical-align: middle;
}

.option__image {
    height: 80px;
    width: 60px;
    object-fit: cover;
    margin-right: 10px;
}

.multiselect__option--highlight {
    background: #1e73be;
    outline: none;
    color: #fff;
}

.checkbox-label {
    display: block;
}

.custom-check {
    position: absolute;
    right: 1vw;
}

#card-material .card-body {
    padding: 10px 10px 10px;
}

#m-create-material .card-body {
    padding: 10px 10px 10px;
}

.barang-card {
    box-shadow: 0 0.1875rem 1rem rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
}

.card-disabled {
    background-color: rgba(245, 245, 245, 1);
    opacity: 0.5;
}

.image-gallery {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 10px;
    background: #e4e5e630;
    padding: 10px;
    border-radius: 5px;
    overflow-x: scroll;
}

.image-gallery>li {
    flex-basis: 250px;
    /* width: 350px; */
    list-style: none;
}

.image-gallery li .expandable-image img {
    object-fit: cover;
    width: 250px;
    height: 250px;
    vertical-align: middle;
    border-radius: 5px;
}

div .expandable-image img {
    height: 65px;
    object-fit: cover;
}

.swal2-popup.swal2-toast .swal2-html-container {
    margin: 0.1em 0.5em;
}

.swal2-popup.swal2-toast .swal2-title {
    margin: 0.1em 0.5em;
}

.dropdown-item {
    position: relative;
    padding: 5px 5px;
    border-bottom: 1px solid #c8ced3;
}

.check-all-for-mobile {
    display: none;
}

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-bottom: 0;
}

.styled-checkbox+label:before {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: white;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.266);
    border-radius: 5px;
}

.styled-checkbox:hover+label:before {
    background: white;
}

.styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
    background: #304f7b;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
        4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.position-stock-mobile {
    text-align: center;
}

.position-trash-mobile {
    text-align: center;
}

.font-my-style {
    font-weight: bold;
}

.my-name-material {
    font-weight: normal;
}

.my-stock {
    display: none;
}

@media only screen and (max-width: 992px) {
    .btn-labeled-for-mobile {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
    }

    .btn-hide-close {
        display: none;
    }

    .show-icon {
        display: inline-block;
    }

    .table-bordered {
        border: unset;
    }

    .multiselect {
        width: auto;
        display: block;
    }

    .my-filter {
        display: none;
    }

    .check-all-for-mobile {
        display: block;
        margin-bottom: 0.5rem;
        margin-right: 10px;
    }

    .hide-quantity {
        display: none;
    }

    .hide-stock {
        display: none;
    }

    .hide-action-remove {
        display: none;
    }

    .view-row-cart-mobile {
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: 0;
        margin-right: 0;
    }

    .view-col-cart-mobile {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 10px;
    }

    .position-stock-mobile {
        text-align: left;
        margin-bottom: 15px;
    }

    .position-quantity-mobile {
        margin-inline-start: auto;
        margin-bottom: 15px;
    }

    .position-trash-mobile {
        text-align: right;
        margin-bottom: 15px;
    }

    .font-my-style {
        font-weight: normal;
    }

    .my-name-material {
        font-weight: bold;
    }

    .my-stock {
        display: inline-block;
        font-weight: bold;
    }
}

@media only screen and (max-width: 1024px) {
    .check-all-for-mobile {
        display: block;
        margin-bottom: 0.5rem;
    }

    .styled-checkbox+label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: white;
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
    }

    .pagination-wrapper .input-group-text {
        display: block;
    }

    .image-gallery>li {
        flex-basis: 100px;
        list-style: none;
    }

    .image-gallery li .expandable-image img {
        object-fit: cover;
        width: 100px;
        height: 100px;
        vertical-align: middle;
        border-radius: 5px;
    }

    div .expandable-image img {
        width: 150px;
        height: 200px;
        object-fit: cover;
    }

    .my-iframe {
        width: 100px;
        height: 100px;
    }

    .my-custom-table tbody th,
    .my-custom-table tbody td {
        position: relative;
    }

    .my-custom-table th,
    .my-custom-table td {
        padding: 0.6rem;
        vertical-align: middle;
    }

    /* Force table to not be like tables anymore */
    .my-custom-table thead,
    .my-custom-table tbody,
    .my-custom-table th,
    .my-custom-table td,
    .my-custom-table tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .my-custom-table thead tr,
    .my-custom-table tfoot tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .my-custom-table td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important;
        text-align: left;
    }

    .my-custom-table td:before {
        position: absolute;
        top: 50%;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        left: 25%;
        margin: 0;
        margin: 0;
        transform: translate(-50%, -50%);
    }

    .my-custom-table td:nth-child(1) {
        height: 100%;
        top: 0;
        left: 0;
        font-weight: bold;
        text-align: left;
        background-color: #304f7b;
        color: white;
    }

    .my-custom-table-main td:nth-child(1) {
        height: 100%;
        top: 0;
        left: 0;
        font-weight: bold;
        text-align: left;
        background-color: #ddd;
        color: white;
    }

    .table-material td:nth-of-type(1):before {
        content: "No.";
    }

    .table-material td:nth-of-type(2):before {
        content: "Material/Bahan";
    }

    .table-material td:nth-of-type(3):before {
        content: "Jumlah";
    }

    .table-user-maintenance td:nth-of-type(1):before {
        content: "No.";
    }

    .table-user-maintenance td:nth-of-type(2):before {
        content: "Tanggal Bertugas";
    }

    .table-user-maintenance td:nth-of-type(3):before {
        content: "Nama";
    }

    .table-user-maintenance td:nth-of-type(4):before {
        content: "No. HP";
    }

    .table-user-maintenance td:nth-of-type(5):before {
        content: "Kode";
    }

    .table-user-maintenance td:nth-of-type(6):before {
        content: "Foto";
    }

    .table-history td:nth-of-type(1):before {
        content: "Tanggal";
    }

    .table-history td:nth-of-type(2):before {
        content: "Status";
    }

    .table-history td:nth-of-type(3):before {
        content: "Waktu Proses";
    }

    .table-history td:nth-of-type(4):before {
        content: "Keterangan";
    }

    .table-daftar-fpp td:nth-of-type(1):before {
        content: "";
    }

    .table-daftar-fpp td:nth-of-type(2):before {
        content: "No. Pengajuan";
    }

    .table-daftar-fpp td:nth-of-type(3):before {
        content: "Tanggal";
    }

    .table-daftar-fpp td:nth-of-type(4):before {
        content: "Cabang";
    }

    .table-daftar-fpp td:nth-of-type(5):before {
        content: "Kabupaten/Kota";
    }

    .table-daftar-fpp td:nth-of-type(6):before {
        content: "Pengajuan";
    }

    .table-daftar-fpp td:nth-of-type(7):before {
        content: "Status";
    }

    .table-daftar-fpp td:nth-of-type(8):before {
        content: "Aksi";
    }
}

.swal2-confirm {
    margin: 0 10px;
}
</style>
  